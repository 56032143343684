import React from 'react'

import { Layout } from '@organisms'

const NotFoundPage = () => (
  <Layout>
    <div>
      <h1>404 Page not found</h1>
    </div>
  </Layout>
)

export default NotFoundPage
